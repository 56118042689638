import * as React from 'react';
import DesignCompany from 'views/DesignCompany';

const IndexPage = () => {
  return <DesignCompany />;
};

export default IndexPage;

export const Head = () => <title>Home Page</title>;
